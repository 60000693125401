// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-female-procedures-js": () => import("./../../../src/pages/female-procedures.js" /* webpackChunkName: "component---src-pages-female-procedures-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-male-procedures-js": () => import("./../../../src/pages/male-procedures.js" /* webpackChunkName: "component---src-pages-male-procedures-js" */),
  "component---src-pages-non-profit-js": () => import("./../../../src/pages/non-profit.js" /* webpackChunkName: "component---src-pages-non-profit-js" */)
}

